import React, { useState, useEffect }from 'react'
import { useTranslation  } from 'react-i18next';
import resetPasswordService from '../services/resetPassword'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import LogoWhite from '../assets/images/logo_ipbroker_blanco.svg';
import Background from '../assets/images/background_halfwhite.jpg';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';


function ResetPassword() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [username, setUserName] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [login, setLogin] = useState('');
    const [code, setCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatNewPassword, setRepeatNewPassword] = useState('');
    const [email, setEmail] = useState('');
    const [codeInput, setCodeInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({sendCode:true, checkCode:false, resetPassword:false});

    useEffect(() => {
        setErrorMsg('');
      }, [username, newPassword, codeInput, repeatNewPassword]);

    const postResetPassword = async ({username, code, new_password}) => {
        try {
            const response = await resetPasswordService({login: username,code: code, new_password: new_password});
            return response;
        } catch (error) {
            setErrorMsg(error.message)
        }
    }


    const handleSubmitSendCode = async (ev) => {
        ev.preventDefault();
        setIsLoading(true);
        const response = await postResetPassword({username: username});
        if (response) {
            setLogin(response.login);
            setCode(response.code);
            setEmail(response.email);
            setState({sendCode:false, checkCode:true, resetPassword:false});
        }
        setIsLoading(false);
    }

    const handleSubmitCheckCode = (ev) => {
        ev.preventDefault();
        setIsLoading(true);
        if(code === codeInput){
            setState({sendCode:false, checkCode:false, resetPassword:true});
        }else{
            setErrorMsg('El código es incorrecto');
        }
        setIsLoading(false);
    }

    const handleSubmitResetPassword = async (ev) => { 
        ev.preventDefault();
        setIsLoading(true);
        if(newPassword === repeatNewPassword){
            const response = await postResetPassword({username: login, code: code, new_password: newPassword});
            if (response) {
                setUserName('');
                setCode('');
                setNewPassword('');
                setRepeatNewPassword('');
                setCodeInput('');
                setErrorMsg('');
                navigate('/login');
            }
        }else{
            setErrorMsg('Las contraseñas no coinciden');
            setIsLoading(false);
            return;
        }
        setIsLoading(false);
    }


  return (
    <div className='d-flex flex-column justify-content-center align-items-center body-login' style={{ 
      backgroundImage: `url(${Background})`,
      backgroundSize: 'cover', 
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
    }}>
        <section className='container-login'>
            <div className='text-center'>
                <img src={LogoWhite} alt='IPBROKER' />
            </div>

            <div className='login'>
                <p className={errorMsg ? 'errmsg' : 'offscreen'} aria-live='assertive'>
                {errorMsg}
                </p>

                {state.sendCode?(
                        <Form className="resetPassword-form" onSubmit={handleSubmitSendCode}>
                            <p>{t('msg-send-code')}</p>
                            <Form.Group className="mb-0">
                                <Form.Label>{t('username')}</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder={t('username')}
                                    required 
                                    name='username'
                                    autoComplete='off'
                                    onChange={(e) => setUserName(e.target.value)}
                                    value={username}
                                    />
                                    
                            </Form.Group>
                            <Button variant="outline-warning" type="submit">
                                {t('btn-request')}
                            </Button>
                        </Form>
                    ):null
                }

                {state.checkCode?(
                        <Form onSubmit={handleSubmitCheckCode}>
                            <p>{t('msg-code')}</p>
                            <Form.Group className="mb-0">
                                <Form.Label>{t('code')}</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder={t('code')} 
                                    required 
                                    name='codeinput'
                                    autoComplete='off'
                                    onChange={(e) => setCodeInput(e.target.value)}
                                    value={codeInput}
                                    />
                                
                            </Form.Group>
                            <Button variant="outline-warning" type="submit">
                                {t('btn-accept')}
                            </Button>
                        </Form>
                    ):null
                }

                {state.resetPassword?(
                        <Form onSubmit={handleSubmitResetPassword}>
                            <Form.Group className="mb-0">
                                <Form.Label>{t('new-password')}</Form.Label>
                                <Form.Control 
                                    type="password"
                                    required 
                                    name='newPassword'
                                    autoComplete='off'
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    value={newPassword}
                                    />  
                            </Form.Group>
                            <Form.Group className="mb-0">
                                <Form.Label>{t('repeat-password')}</Form.Label>
                                <Form.Control 
                                    type="password"
                                    required 
                                    name='repeatNewPassword'
                                    autoComplete='off'
                                    onChange={(e) => setRepeatNewPassword(e.target.value)}
                                    value={repeatNewPassword}
                                    />  
                            </Form.Group>
                            <Button variant="outline-warning" type="submit">
                                {t('btn-accept')}
                            </Button>
                        </Form>
                    ):null
                }   

                {isLoading ? <Loading /> : null}
                <Link to={'/'} className='link'>{t('return')}</Link>
            </div>
        </section>
    </div>
    
  )
}

export default ResetPassword