import React, {useState, useEffect} from 'react'
import ReactCountryFlag from "react-country-flag"
import { useTranslation } from "react-i18next";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Cookies from 'js-cookie';

function LanguageSelector({ variant='warning' }) {
    const { t, i18n } = useTranslation();
    let languages = [['es',t('spanish')],['us',t('english')]]
    const [activeLang, setActiveLang] = useState();

    useEffect(() => {
      if(Cookies.get('lang')){
        setActiveLang(Cookies.get('lang').split(','));
      }else{
        setActiveLang(languages[0]);
        i18n.changeLanguage(languages[0]);
      }
    }, []);

    const onClickLang = (e) => {
      const lang = findLang(e.target.getAttribute('value'))
      setActiveLang(lang);
      i18n.changeLanguage(lang[0]);
      Cookies.set('lang',lang,{expires: 365});
    }

    const findLang = (language) => {
      return languages.find(lang => lang[0] === language)
    }
    

  return (
    
    <div style={{display: 'flex',  justifyContent:'right', alignItems:'center'}}>
        {[DropdownButton].map((DropdownType, idx) => (
          <DropdownType
            as={ButtonGroup}
            size="sm"
            variant={variant}
            title={<><ReactCountryFlag countryCode={activeLang?.[0]} svg /> {findLang(activeLang?.[0])?.[1]}</>}

          >
            {languages.map((lang, index) => {
              return <Dropdown.Item value={lang[0]} as="button" onClick={onClickLang}>
                <ReactCountryFlag countryCode={lang[0]} svg /> {lang[1]}
              </Dropdown.Item>
            })}
          </DropdownType>
        ))}
    </div>
  )
}

export default LanguageSelector