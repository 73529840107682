import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import IdleTimeOutHandler from './IdleTimeOutHandler';
import Header from '../components/Header';

function RquireAuth() {
  const { user } = useAuth();
  const location = useLocation();

  return user ? (<><IdleTimeOutHandler/><Header/> <Outlet/> </>) : <Navigate to='/login' state={{ from: location }} replace />;
}

export default RquireAuth;
