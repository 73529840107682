import React from 'react'
import styled from 'styled-components'

function Text({ text, size='medium', color='#000', bold, ...props }) {
    const sizeMap = {
        small: '1em',
        medium: '1.2em',
        large: '2em'
    }

    


    const Text = styled.div`
    font-size: ${sizeMap[size]};
    color: ${color};
    font-weight: ${bold ? 'bold' : 'normal'};
    white-space: ${props.whiteSpace || 'normal'};
    font-family: ${props.fontFamily ? props.fontFamily :'Titillium Web'};
    text-transform: ${props.textTransform ? props.textTransform :'uppercase'};
    flex-grow: ${props.flexGrow ? props.flexGrow :'1'};
    text-align: ${props.textAlign ? props.textAlign :'center'};
    `

  return (
    <Text>{text}</Text>
  )
}

export default Text