import { useEffect, useState } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import useUser from './useUser';
import useLocalStorage from './useLocalStorage';
import loginService from '../services/login';

const useAuth = () => {
  const { user, addUser, removeUser } = useUser();
  const { getItem } = useLocalStorage();
  const [state, setState] = useState({ loading: false, error: false });
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const user = getItem('user');
    if (user) {
      addUser(JSON.parse(user));
    }
  }, []);

  useEffect(() => {
    if (getItem('user')) {
      if (location.state?.from) {
        navigate(location.state.from);
      }
    }
  }, []);

  const login = ({ username, password }) => {
    setState({ loading: true, error: false });
    loginService({ username, password })
      .then((res) => {
        addUser({
          name: res.name,
          partner_id: res.partner_id,
          odooSid: res.odooSid,
        });
        navigate('/');
      })
      .catch((err) => {
        setState({ loading: false, error: true });
        console.log(err);
      });
  };

  const logout = () => {
    removeUser();
    navigate('/login', { replace: true });
  };

  return {
    hasLoginError: state.error,
    isLoginLoading: state.loading,
    user,
    login,
    logout,
  };
};

export default useAuth;
