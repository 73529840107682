import React from 'react';
import moment from 'moment/min/moment-with-locales';
import { useTranslation } from 'react-i18next';
import useAuth from '../hooks/useAuth';
import { useState, useEffect, useCallback, useMemo } from 'react';
import ButtonPayNow from '../components/ButtonPayNow';
import Loading from '../components/Loading';
import invoicesService from '../services/getInvoices';
import DonwloadInvoicePdf from '../components/DonwloadInvoicePdf';
import DataTable from 'react-data-table-component';
import FilterComponent from '../components/FilterComponent';
import Text from '../components/Text';

function InvoiceList() {
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const [data, setData] = useState([]);
  const [state, setState] = useState({ loadingData: true });
  const [filterText, setFilterText] = useState('');
  const [filterPaymentState, setFilterPaymentState] = useState('not_paid');
  const [filterField, setFilterField] = useState('invoice_date');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const paymentStates = [
    ['not_paid', t('not-paid')],
    ['paid', t('paid')],
    ['partial', t('partial')],
    ['reversed', t('reversed')],
    ['all', t('all')]
  ];

  const filterFields = [{'field': 'name', 'label': t('number')}, {'field': 'invoice_date', 'label': t('date')}];

	const filteredItems = data.filter( item => {
    let value = item[filterField];

    if(filterField === 'invoice_date' && value){
      value = moment(value).locale(i18n.language==='us'?'en':i18n.language).format('L')
    }

    if(filterPaymentState === 'all'){
      return value && value.toLowerCase().includes(filterText.toLowerCase())
    }else{
      return value && value.toLowerCase().includes(filterText.toLowerCase()) && item.payment_state === filterPaymentState
    }
  }
	);

  const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent 
        filterFields={filterFields}
        filterField={filterField}
        onFilterField={e => {
          setFilterField(e.target.getAttribute('value')) 
          handleClear()
        }}
        onFilter={e => setFilterText(e.target.value)} 
        onClear={handleClear} 
        filterText={filterText} 
        paymentStates={paymentStates}
        filterPaymentState={filterPaymentState}
        onFilterPaymentState={e => setFilterPaymentState(e.target.getAttribute('value'))}
         />
		);
	}, [filterText, filterPaymentState, resetPaginationToggle, i18n.language]);


  const columns = [
    {
      id: 'id',
      name: <Text text="ID" bold={true} whiteSpace="nowrap" fontSize="medium"></Text>,
      selector: row => row.id,
      sortable: true,
      omit: true,
    },
    {
      id: 'name',
      grow: i18n.language === 'es' ? 0.5 : 1,
      name: <Text text={t('number')} bold={true} whiteSpace="nowrap"></Text>,
      selector: row => row.name,
      sortable: true,
      //cell: row => <Text text={row.name} bold={true} whiteSpace="nowrap" fontFamily="verdana"></Text>,
      cell: row => <Text text={row.name} whiteSpace="nowrap"></Text>,
    },
    {
      id: 'invoice_date',
      grow: i18n.language === 'es' ? 0.5 : 1,
      name:<Text text={t('date')} bold={true} whiteSpace="nowrap" flexGrow="1" textAlign="center"></Text>,
      //selector: row => moment(row.invoice_date).locale(i18n.language==='us'?'en':i18n.language).format('L'),
      sortable: true,
      cell: row => <Text text={moment(row.invoice_date).locale(i18n.language==='us'?'en':i18n.language).format('L')}> whiteSpace="nowrap" flexGrow="1" textAlign="center"></Text>,
    },
    {
      id: 'invoice_date_due',
      grow: i18n.language === 'es' ? 1.3 : 1,
      name:<Text text={t('due-date')} bold={true} whiteSpace="nowrap"></Text>,
      selector: row => moment(row.invoice_date_due).locale(i18n.language==='us'?'en':i18n.language).format('L'),
      sortable: true,
      cell: row => <Text text={moment(row.invoice_date).locale(i18n.language==='us'?'en':i18n.language).format('L')}> whiteSpace="nowrap" flexGrow="1" textAlign="center"></Text>,
    },
    {
      id: 'amount_total',
      grow: 1,
      name: <Text text={t('amount-total')} bold={true} whiteSpace="nowrap"></Text>,
      //selector: row => `${row.amount_total} ${row.currency}`,
      cell: row => <span style={{flexGrow: '1', textAlign: 'center', fontSize: 1.2 + 'em'}}> {row.amount_total} {row.currency} </span>,
      
     
     
    },
    {
      id: 'amount_residual',
      grow: 1,
      name: <Text text={t('amount-residual')} bold={true} whiteSpace="nowrap"></Text>,
      //selector: row => `${row.amount_residual} ${row.currency}`,
      cell: row => <span style={{flexGrow: '1', textAlign: 'center', fontSize: 1.2 + 'em'}}> {row.amount_residual} {row.currency} </span>,
    },
    {
      id: 'payment_state',
      grow: 1,
      name: <Text text={t('payment-state')} bold={true} whiteSpace="nowrap"></Text>,
      selector: row => row.payment_state,
      sortable: true,
      cell: row => <div style={{flexGrow: '1', textAlign: 'center'}} className={`badge ${paymentStateClassName(row)}`} >{paymentStates.find(state => state[0] === row.payment_state)[1]} </div>,
    }, 
    {
      id: 'download',
      button: true,
      cell: row => <DonwloadInvoicePdf invoice_id={row.id} invoice_name={row.name} />,
    },
    {
      id: 'pay',
      button: true,
      cell: row  => row.payment_state === 'not_paid' ||  row.payment_state === 'partial'?<ButtonPayNow invoice_data={row}/>:null ,
    }
  ];


  const getInvoices = useCallback(() => {
    invoicesService({ partner_id: user.partner_id, odooSid: user.odooSid })
      .then((res) => {
        if(res.error){
          console.log(res.error?.message);
        }else{
          setData(res);
        }
        setState({ loadingData: false });
      })
      .catch((err) => {
        console.log(err);
        setState({ loadingData: false });
        setData([]);
      });
  }, [setData, user]);

  const paymentStateClassName = (element) => {
    let className = 'badge-info-ipb';

    if (element.payment_state === 'not_paid') {
      className = 'badge-danger-ipb';
    }
    if (element.payment_state === 'paid') {
      className = 'badge-success-ipb';
    }
    if (element.payment_state === 'partial') {
      className = 'badge-warning-ipb';
    }
    return className;
  };

  useEffect(() => {
    if (Object.keys(user).length) {
      getInvoices();
    }
  }, [user]);

  return (
    <>
      {state.loadingData ? (
        <Loading />
      ) :!data?.length ? (
        <h2>{t('no-bills')}</h2>
      ) :
    
       (
        
        <div className='container table-shadow bg-white'>
          <div className='row'>
            <div className='col-12'>
              <DataTable
                highlightOnHover={true}
                defaultSortFieldId='name'
                striped={true}
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                paginationComponentOptions={{rowsPerPageText: t('rows-per-page'), rangeSeparatorText: t('of')}}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
              />
            </div>
          </div>
        </div>
        
      )
      }
    </>
  );
}

export default InvoiceList;
