import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';

function FilterComponent({ filterText, onFilter, onClear, paymentStates, filterPaymentState, onFilterPaymentState, filterFields, filterField, onFilterField}) {
  const { t, i18n } = useTranslation();
  const [selectedField, setSelectedField] = useState({});

  const onChangeClickFilterField = (e) => {
    setSelectedField(filterFields.filter((field) => field.field === e.target.getAttribute('value'))[0])
    onFilterField(e)
  }

  useEffect(() => {
    setSelectedField(filterFields.filter((field) => field.field === filterField)[0])
  }
  , [i18n.language])


  return (
    <>
        <div class="w-100 d-flex justify-content-end p-0 filters-block">

          <div className='me-3 text-dark'>
            {t('filter-by')}:
            {[DropdownButton].map((DropdownType) => (
              <DropdownType
                className='ms-2 bg-primary'
                as={ButtonGroup}
                size="sm"
                variant="primary"
                title={selectedField.label}
              >
                {filterFields.map((field) => {
                  return <Dropdown.Item as="button" onClick={onChangeClickFilterField} value={field.field}>{field.label}</Dropdown.Item>
                })}
              </DropdownType>
            ))}
          </div>
          
          
          <div className="me-3">
              <InputGroup size='sm'>
                  <Form.Control
                      placeholder={selectedField.label}
                      value={filterText}
                      onChange={onFilter}
                   

                  />
                  <Button variant="primary" type='button'  onClick={onClear}>
                   < i class="ri-equalizer-fill"></i> 
                  </Button>
              </InputGroup>
          </div>

          <div className='text-dark'>
          {t('payment-state')}
          {[DropdownButton].map((DropdownType, idx) => (
            <DropdownType
              className='ms-2 bg-primary'
              as={ButtonGroup}
              size="sm"
              variant="primary"
              title={paymentStates.filter((paymentState) => paymentState[0] === filterPaymentState)[0][1]}
            >
              {paymentStates.map((paymentState) => {
                return <Dropdown.Item as="button" onClick={onFilterPaymentState} value={paymentState[0]}>{paymentState[1]}</Dropdown.Item>
              })}
            </DropdownType>
          ))}
          </div>
        </div>
        

    </>
  )
}

export default FilterComponent