import React from 'react';
import Login from './pages/Login';
import Layout from './pages/Layout';
import InvoiceList from './pages/InvoiceList';
import RquireAuth from './components/RquireAuth';
import { Route, Routes} from 'react-router-dom';
// import Home from './pages/Home';
import PaymentProcess from './pages/PaymentProcess';
import ChangePassword from './pages/ChangePassword';
import ResetPassword from './pages/ResetPassword';
import 'remixicon/fonts/remixicon.css';


function App() {
 
  return (
    <>
     
     <Routes>
      <Route path='/' element={<Layout />}>
        {/* Public */}
        <Route path='login' element={<Login />} />
        <Route path='reset_password' element={<ResetPassword />} />

        {/* Protect */}
          <Route element={<RquireAuth />}>
            {/* <Route path='/' element={<Home />} /> */}
            <Route path='/' element={<InvoiceList />} />
            <Route path='/myInvoices' element={<InvoiceList />} />
            <Route path='/paymentProcess' element={<PaymentProcess />} />
            <Route path='/change_password' element={<ChangePassword />} />
        </Route>
      </Route>
    </Routes>
    </>
   
  );
}

export default App;
