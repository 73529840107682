import React, { useEffect, useState} from 'react';
import moment from 'moment'
import { IdleTimeOutModal } from './IdleTimeOutModal'
import useLocalStorage from '../hooks/useLocalStorage';
import useAuth from '../hooks/useAuth';

const IdleTimeOutHandler = (props)=>{
    const { logout } = useAuth();
    const {setItem,getItem}=useLocalStorage();
    const[showModal,setShowModal]=useState(false)
    const[isLogout,setLogout]=useState(false)
    let timer=undefined;
    const events= ['click','load','keydown']


    const eventHandler =(eventType)=>{
        if(!isLogout){
            setItem('lastInteractionTime',JSON.stringify(moment().toString()))
            if(timer){
                startTimer();
            }
        }
    };
    
    useEffect(()=>{
        addEvents();
        return (()=>{
            removeEvents();
            clearTimeout(timer);
        })
    },[])
    
    const startTimer=()=>{
        
        if(timer){
            clearTimeout(timer)
        }
        timer=setTimeout(()=>{
            
            let lastInteractionTime=getItem('lastInteractionTime')
            const diff = moment.duration(moment().diff(lastInteractionTime));
            let timeOutInterval=props.timeOutInterval?props.timeOutInterval:5*60*1000;
            if(isLogout){
                clearTimeout(timer)
            }else{
                if(diff._milliseconds<timeOutInterval){
                    startTimer();
                }else{
                    setShowModal(true)
                }
            }
            
        },props.timeOutInterval?props.timeOutInterval:5*60*1000)
        
    }


    const addEvents=()=>{
        events.forEach(eventName=>{
            window.addEventListener(eventName,eventHandler)
        })
        
        startTimer();
    }
    
    const removeEvents=()=>{
        events.forEach(eventName=>{
            window.removeEventListener(eventName,eventHandler)
        })
    };
    
    const handleContinueSession = ()=>{
        setShowModal(false)
        setLogout(false)
    }
    const handleLogout = ()=>{
        removeEvents();
        clearTimeout(timer);
        setLogout(true)
        setShowModal(false)
        logout();
    }
    
    return(
        <div>
        
        <IdleTimeOutModal 
        showModal={showModal} 
        handleContinue={handleContinueSession}
        handleLogout={handleLogout}
        />
        </div>
        )
        
    }
    
    export default IdleTimeOutHandler;