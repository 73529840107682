import React from 'react';
import {useTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';
import LogoWhite from '../assets/images/logo_ipbroker_blanco.svg';
import useAuth from '../hooks/useAuth';
import LanguageSelector from './LanguageSelector';

function Header() {
  const { t } = useTranslation();
  const { user, logout } = useAuth();


  return (
    <>
        <section className='header'>
          <header className='p-0 mb-5 border-bottom bg-header'>
            <div className='container'>
              <div className='d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start'>
                <Link
                  to='/'
                  className='d-flex align-items-center mb-2 mb-lg-0 link-body-emphasis text-decoration-none pe-2'>
                  <img src={LogoWhite} alt='IPBROKER' height='50' width='150' />
                </Link>

                <ul className='nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0'>
                  {/* <li>
                    <Link to='/' className='nav-link px-2 link-secondary'>
                      Inicio
                    </Link>
                  </li> */}
                  <li>
                    <Link to='/myInvoices' className='nav-link px-2 link-light logo-var'>
                    {t('invoices')}
                    </Link>
                  </li>
                </ul>

                <div className='me-lg-3'>
                  <LanguageSelector variant="outline-light" />
                </div>

                <div className='d-flex'>
                <i class="ri-user-fill me-1"></i> 
                <div className='dropdown text-end'>
                  <a
                    href='#'
                    className='d-block link-light text-decoration-none dropdown-toggle'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'>
                    <span>{user?.name}</span>
                  </a>
                  <ul className='dropdown-menu text-small'>
                  <li>
                    <button className='dropdown-item'>
                      <Link to='/change_password' className='nav-link'>
                      {t('change-password')}
                      </Link>
                    </button>
                  </li>
                    <li>
                      <button className='dropdown-item' onClick={logout}>
                       {t('logout')}
                      </button>
                    </li>
                  </ul>
                </div>
                </div>
              </div>
            </div>
          </header>
        </section>
      
    </>
  );
}

export default Header;
