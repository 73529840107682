export default function getInvoices({ partner_id, odooSid }) {
  return fetch("/api/invoices", {
    method: "POST",
    headers: {"content-type": "application/json", "odoo": odooSid},
    body: JSON.stringify({ 'partner_id':partner_id }),
  }).then(res => {
    if (!res.ok) {
            throw new Error('Se produjo un error al obtener las facturas');
          }
    return res.json();
  }).then(data => {
    if(data.error){
      throw new Error(data.error.data.message);
    }
    return data.result;
  })
}
