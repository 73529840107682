import React from 'react';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import Loading from '../components/Loading';
import Alert from 'react-bootstrap/Alert';
import Cookies from 'js-cookie';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

function PaymentProcess() {
    const { t } = useTranslation();
    const [state, setState] = useState({ 
        processingPayment: true, 
        paymentSuccess: false, 
        paymentError: false,
    });
    const [tx, setTx] = useState({});
   
    useEffect(() => {     
        const paymentSuccess = Boolean(Cookies.get('redsys_result_ok'))
        const paymentError = Boolean(Cookies.get('redsys_result_ko'))
        setState({
            paymentSuccess:paymentSuccess, 
            paymentError:paymentError,
            processingPayment:false
        })
    },[])

    useEffect(() => {
        const txCookie = Cookies.get('redsys_tx')
        if (txCookie){
            setTx(JSON.parse(txCookie))
        }
    },[state.paymentSuccess])

  return (
    state.processingPayment?<Loading text='Processing Payment'/>:(
        <div className='container d-flex justify-content-center'>
            {state.paymentSuccess?
            <Card border="success" className='w-50' >
                <Card.Header className="bg-success">{t('payment-success')}</Card.Header>
                <Card.Body>
                    <Card.Title>{t('transaction-details')}</Card.Title>
                    <ListGroup className="list-group-flush">
                        <ListGroup.Item>{t('invoice')} <strong>{tx.invoice}</strong></ListGroup.Item>
                        <ListGroup.Item>{t('amount')} <strong>{tx.amount}</strong></ListGroup.Item>
                        <ListGroup.Item>{t('date')} <strong>{tx.date}</strong></ListGroup.Item>
                        <ListGroup.Item>{t('tx-reference')} <strong>{tx.reference}</strong></ListGroup.Item>
                    </ListGroup>
                </Card.Body>
            </Card>
            :
            <Alert className='text-center w-50' variant='danger'>{t('payment-error')}</Alert>
            }
        </div>
    )
  )
}

export default PaymentProcess