import React, {useState}from 'react';
import Button from 'react-bootstrap/Button';
import LoadingFullScreen from './LoadingFullScreen';
import { invoicePdf } from '../services/invoicePdf';
import useAuth from '../hooks/useAuth';

export default function DonwloadInvoicePdf({invoice_id, invoice_name}) {
    const [isLoading, setLoading] = useState(false);
    const {user} = useAuth();

    const handleClick = () => {
        setLoading(true);
        invoicePdf({invoice_id: invoice_id, odooSid: user.odooSid})
        .then((res) => {
            donwloadPDF(res);
            setLoading(false);
        })
        .catch((err) => {
            console.log(err);
        })
       
    }

    const donwloadPDF = (pdf) =>{
        const link = document.createElement('a');
        link.download = `${invoice_name}.pdf`;
        link.href = `data:application/octet-stream;base64,${pdf}`;
        link.click();
    }


  return (isLoading?<LoadingFullScreen/>:
    <Button 
        variant='outline-danger' 
        size='sm' 
        disabled={isLoading}
        onClick={handleClick}
        className='btn-downloadpdf-ipb '
    >
        <i class="ri-file-pdf-2-line"></i><span>  PDF</span>
    </Button>
  )
}
