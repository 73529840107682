import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const IdleTimeOutModal = ({showModal, handleContinue, handleLogout, remainingTime}) => {
    const { t } = useTranslation();

    const autoLogout = ()=>{
        setTimeout(()=>{
            handleLogout()
        },5000)
    }

    return (
        <Modal onShow={autoLogout} show={showModal} onHide={handleContinue}>
            <Modal.Header closeButton>
            <Modal.Title>{t('warning')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t('idle-message')}</Modal.Body>
            {/* <Modal.Footer>
            <Button variant="danger" onClick={handleLogout}>
                Cerrar Sesion
            </Button>
            <Button variant="primary" onClick={handleContinue}>
                Continuar
            </Button>
            </Modal.Footer> */}
        </Modal>
    )
}