export default function ResetPassword({login, code, new_password}) {
    return fetch("/api/reset_password", {
        method: "POST",
        headers: {"content-type": "application/json"},
        body: JSON.stringify({login, code, new_password}),
      }).then(res => {
        if (!res.ok) {
          throw new Error('Response is NOT ok');
        }
        return res.json();
      }).then(data => {
          if (data.error) {
            throw new Error(data.error);
          }
          return data;
      })

}