export default function ChangePassword({old_pwd, new_password, confirm_pwd, odooSid}) {
    return fetch("/api/change_password", {
        method: "POST",
        headers: {"content-type": "application/json","odoo": odooSid},
        body: JSON.stringify({old_pwd, new_password, confirm_pwd}),
      }).then(res => {
        if (!res.ok) {
          throw new Error('Response is NOT ok');
        }
        return res.json();
      }).then(data => {
          return data;
      })

}