import React from 'react';
import { useTranslation  } from 'react-i18next';
import { useRef, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import Logo from '../assets/images/ipbroker.png';
import LogoWhite from '../assets/images/logo_ipbroker_blanco.svg';
import Background from '../assets/images/background_halfwhite.jpg';
import Loading from '../components/Loading';
import { Link } from 'react-router-dom';
import LanguageSelector from '../components/LanguageSelector';

const Login = () => {
  const { t } = useTranslation();
  const { login, isLoginLoading, hasLoginError } = useAuth();
  const userRef = useRef();
  const errRef = useRef();
  const [username, setUserName] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [username, pwd]);

  useEffect(() => {
    if (hasLoginError) {
      setErrMsg(t('invalid_credentials'));
    }
  }, [hasLoginError]);

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    try {
      setUserName('');
      setPwd('');
      login({ username, password: pwd });
    } catch (err) {
      console.log(err);
      setErrMsg(err.message);
      errRef.current.focus();
    }
  };

  return (
    <div className='d-flex flex-column justify-content-center align-items-center body-login' style={{ 
      backgroundImage: `url(${Background})`,
      backgroundSize: 'cover', 
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
    }}>
      <section className='container-login'>
        <div className='text-center'>
          <img src={LogoWhite} alt='IPBROKER' />
        </div>
        <div className='login'>
          <LanguageSelector />
          <p ref={errRef} className={errMsg ? 'errmsg' : 'offscreen'} aria-live='assertive'>
            {errMsg}
          </p>
          <form onSubmit={handleSubmit}>
            <label htmlFor='username'>{t('username')}</label>
            <input
              type='text'
              id='username'
              ref={userRef}
              autoComplete='off'
              onChange={(e) => setUserName(e.target.value)}
              value={username}
              required
              aria-describedby='uidnote'
            />

            
            
            <label htmlFor='password'>{t('password')}</label>

            <input
              type='password'
              id='password'
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
              required
              aria-describedby='pwdnote'
            />



            <button disabled={!username || !pwd ? true : false}>{t('btn-login')}</button>
            <Link to='/reset_password' className='mt-4 link'>{t('forgot-password')}</Link>
            
           
          </form>
          {isLoginLoading ? <Loading /> : null}
        </div>
      </section>
    </div>
  );
};

export default Login;
