import { useState } from 'react';

function useLocalStorage() {
  const [value, setValue] = useState(null);

  const setItem = (key, value) => {
    localStorage.setItem(key, value);
    setValue(value);
  };

  const getItem = (key) => {
    const value = localStorage.getItem(key);
    const user = value?JSON.parse(value):null;
    const now = new Date();

    if(now.getTime() > user?.expiry) {
      removeItem(key);
      return null;
    }
    setValue(value);
    return value;
  };

  const removeItem = (key) => {
    localStorage.removeItem(key);
    setValue(null);
  };

  return { value, setItem, getItem, removeItem };
}

export default useLocalStorage;
