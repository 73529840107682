import { useContext } from 'react';
import AuthContext from '../context/AuthProvider';
import useLocalStorage from './useLocalStorage';

function useUser() {
  const { user, setUser } = useContext(AuthContext);
  const { setItem } = useLocalStorage();

  const addUser = (user) => {
    const expiry = new Date(Date.now() + 6 * (60 * 60 * 1000));
    user.expiry = expiry.setDate(expiry.getDate());
    setUser(user);
    setItem('user', JSON.stringify(user));
  };

  const removeUser = () => {
    setUser(null);
    setItem('user', '');
  };

  return { user, addUser, removeUser };
}

export default useUser;
