export const paymentRedsysRedirect = ({ invoice_id,odooSid}) => {
    const body = {
        'invoice_id':invoice_id,
    }
    return fetch("/api/payment_redsys_redirect", {
        method: "POST",
        headers: {"content-type": "application/json","odoo": odooSid},
        body: JSON.stringify(body)
      }).then(res => {
        if (!res.ok) {
          throw new Error('Response is NOT ok');
        }
        return res.json();
      }).then(data => {
          if(data.error){
            throw new Error(data.error.data.message);
          }
          return data.result;
      })

};
