import React from 'react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import PaymenredsysRedirctModal from './PaymentRedsysRedirectModal';

function ButtonPayNow({ invoice_data }) {
  const { t } = useTranslation();
  const [showModal, setModal] = useState();

  const Toggle = () => setModal(!showModal);

  const onClickPayNow = () => {
    Toggle();
  };

  return (
    <>
      <button onClick={onClickPayNow} className='btn-sm btn-paynow-ipb'>
        <i class="ri-arrow-right-circle-line"></i> {t('pay')}
      </button>
      {showModal ? (
        <PaymenredsysRedirctModal showModal={showModal} close={Toggle} invoice_data={invoice_data} />
      ) : null}
    </>
  );
}

export default ButtonPayNow;
