import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dna } from 'react-loader-spinner';

function Loading({text}) {
  const { t } = useTranslation();
  text = text || t('loading');

  return (
    <div className='mt-5 d-flex flex-column align-items-center'>
      <span>{text}</span>
      <Dna
        visible={true}
        height='80'
        width='80'
        ariaLabel='dna-loading'
        wrapperStyle={{}}
        wrapperClass='dna-wrapper'
      />
    </div>
  );
}

export default Loading;
