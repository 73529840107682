export default function login({ username, password }) {
  return fetch("/api/login", {
    method: "POST",
    headers: {"content-type": "application/json"},
    body: JSON.stringify({'login':username,'password':password}),
  }).then(res => {
    if (!res.ok) {
      throw new Error('Response is NOT ok');
    }
    return res.json();
  }).then(data => {
      if(data.error){
        throw new Error(data.error.data.message);
      }
      return data.result;
  })
}
