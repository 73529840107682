import React from 'react'
import { useTranslation } from 'react-i18next';
import useAuth from '../hooks/useAuth';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { paymentRedsysRedirect } from '../services/paymentRedsys';


function PaymenredsysRedirctModal(props) {    
    const { t } = useTranslation();
    const {user} = useAuth();
    const [action, setAction] = useState('');
    const [Ds_SignatureVersion, setDs_SignatureVersion] = useState('');
    const [Ds_MerchantParameters, setDs_MerchantParameters] = useState('');
    const [Ds_Signature, setDs_Signature] = useState('');

    const getPaymentRedsys = async () => {
        const response = await paymentRedsysRedirect({invoice_id:props.invoice_data.id,odooSid:user.odooSid});
        return response;
    } 

    const handleSubmit = async (ev) => {
        ev.preventDefault();
        const response = await getPaymentRedsys();
        setAction(response.url)
        setDs_SignatureVersion(response.Ds_SignatureVersion)
        setDs_MerchantParameters(response.Ds_MerchantParameters)
        setDs_Signature(response.Ds_Signature)
        return ev.target.form.submit();
    }


  return (
    <>
        {
            props.showModal? (<Modal show={props.showModal} onHide={() => props.close()}>
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title text-dark text-center">{t('confirm-and-pay')}</Modal.Title>
                </Modal.Header>
        
                <Modal.Body className="p-4">
                       <div className="text-dark pb-2 text-center"><strong>{t('invoice')} {props.invoice_data.name}</strong></div>
                       <div className="text-dark pb-2 fw-bold text-center"><strong>{t('total-to-pay')} {props.invoice_data.amount_residual} {props.invoice_data.currency}</strong></div>
                </Modal.Body>
        
                <Modal.Footer>
                    <Button className="button-payment" variant="danger" onClick={() => props.close()}><i class="ri-close-line me-1"></i> 
                    {t('btn-close')}
                    </Button>
                    <form action={action} method='post'>
                        <input type="hidden" name="Ds_SignatureVersion" value={Ds_SignatureVersion}/>
                        <input type="hidden" name="Ds_MerchantParameters" value={Ds_MerchantParameters}/>
                        <input type="hidden" name="Ds_Signature" value={Ds_Signature}/>
                        <Button variant="success" type='button' onClick={handleSubmit}><i class="ri-check-line me-1"></i> 
                        {t('btn-accept')}
                        </Button>
                    </form>
                    <div className="text-dark w-100 fs-12 fw-bold text-end"><strong>{t('legal_advertisement')}</strong></div>

                   
                </Modal.Footer>
            </Modal>):null
        }
    </>
    
  )
}
export default PaymenredsysRedirctModal