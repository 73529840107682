import React from 'react';
import { useTranslation } from 'react-i18next';
import {  useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import Loading from '../components/Loading';
import Alert from 'react-bootstrap/Alert';
import ChangePasswordService from '../services/changePassword';


const ChangePassword = () => {
  const { t } = useTranslation();
  const { user, logout } = useAuth();
  const [old, setOld] = useState('');
  const [new1, setNew1] = useState('');
  const [new2, setNew2] = useState('');
  const [state, setState] = useState({
    loading: false,
    error: false,
    errorMessage: '',
  });

  useEffect(() => {
      setState({ error: false, errorMessage: ''});
  }, [old,    new1,    new2]);
 

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setState({ loading: true });
    if(ev.target.checkValidity()){
      ChangePasswordService({old_pwd:old, new_password:new1, confirm_pwd:new2, odooSid:user.odooSid})
      .then((res) => {
        if(res.error){
          setState({ loading: false });
          setState({ error: true, errorMessage: res.error});
        }else{
          setState({ loading: false });
          setState({ error: false, errorMessage: ''});
          logout();
        }
      })
      .catch((err) => {
        console.log(err);
        setState({ loading: false });
        setState({ error: true, errorMessage: t('unexpected-error')});
      });

    }

    ev.target.classList.add("was-validated");
   
  };

  return (

    <div className='d-flex flex-column pt-5 align-items-center body-login'>
      <div className='row'>
        <div className='col-md-12'>
            {
              state.error?(<Alert className='text-center fw-bold' variant='danger'>{state.errorMessage}</Alert>): null
            }
        </div>
      </div>

       <section className='container-login'>
           

      <div className='login'>
        <div className='col-md-12'>
          <h3 className='main-title text-center'>{t('change-password')}</h3>    
            <form onSubmit={handleSubmit}>
               
                <div className="form-group">
                    <label htmlFor="current">{t('password')}</label>
                    <input 
                      className="form-control form-control-sm "
                      type="password" 
                      name="old"  
                      required="required" 
                      onChange={(e) => setOld(e.target.value)}
                      value={old}
                    />
                    <div className="invalid-feedback">
                    {t('required')}
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="new">{t('new-password')}</label>
                    <input 
                      className="form-control form-control-sm "
                      type="password" 
                      name="new1"  
                      required="required" 
                      onChange={(e) => setNew1(e.target.value)}
                      value={new1}
                    />
                    <div className="invalid-feedback">
                    {t('required')}
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="new2">{t('new-password-verify')}</label>
                    <input 
                      className="form-control form-control-sm "
                      type="password" 
                      name="new2" 
                      required="required" 
                      onChange={(e) => setNew2(e.target.value)}
                      value={new2}
                    />
                    <div className="invalid-feedback">
                    {t('required')}
                    </div>
                </div>
                <button type="submit" className="btn btn-danger mt-2">{t('change-password')}</button>
            </form>
            {state.loading ? <Loading /> : null}
        </div>
      </div>
      </section>
    </div>
  );
};

export default ChangePassword;
