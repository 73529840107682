import Cookies from 'js-cookie';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import us from "./us.json";
import es from "./es.json";

i18n.use(initReactI18next).init({
    lng: Cookies.get('lang')?.split(',')[0] || 'es',
    fallbackLng: "es",
    interpolation: {
        escapeValue: false,
    }, 
    resources: {
        us: us,
        es: es,
    },
});


export default i18n